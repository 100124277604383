import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import ThemeToggle from '../components/themeToggle'
import { StaticQuery, graphql } from "gatsby"

const Header = ({ data }) => (
  <header>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
      }}
      className="flex-none md:flex justify-between items-center pt-8 md:pt-2 px-4"
      data-sal="slide-down"
      data-sal-delay="300"
      data-sal-easing="ease"
    >
      <Link
        className="header-gradetion site-title tracking-wider text-2xl font-black py-8"
        to="/"
      >
        {data.site.siteMetadata.title}
      </Link>
      <ul className="grid grid-cols-2 gap-1 md:gap-4 text-left md:text-right place-content-center list-none mt-4 md:mt-0 mx-0">
        <li className="mb-4">
          <ThemeToggle />
        </li>
        <ul className="flex list-none justify-end mx-0 place-content-center">
          <li className="pr-4">
            <Link to="/blog/" className="text-base font-semibold text-gray-400">
              Blog
            </Link>
          </li>
          <li className="pr-4">
            <Link to="/talk/" className="text-base font-semibold text-gray-400">
              Talk
            </Link>
          </li>
        </ul>
      </ul>
    </div>
  </header>
)

export default function MyHeader(props) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => <Header data={ data } {...props} />}
    />
  )
}

Header.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
