/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
 import * as React from "react"
 import PropTypes from "prop-types"
 import "./layout.css"
 import Header from './header'
 import Link from 'gatsby-link'
 import CookieConsent from "react-cookie-consent";

const Layout = ({ children }) => {
  return (
    <div className="theme-dark bg-primary text-text-main transition-all duration-300">
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <Header />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
          style={{
            background: "#111827",
            boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
          }}
          buttonStyle={{ color: "#ffffff", background: "#7744aa" }}
          declineButtonStyle={{ background: "#2d2d2d" }}
          enableDeclineButton
        >
          This site uses cookies from GA to enhance the user experience 🍪🍪
        </CookieConsent>
        <main>{children}</main>
        <footer
          style={{
            marginTop: `2rem`
          }}
          className="flex-none md:flex justify-between"
        >
          <div className="pb-8">
            © Arisa Fukuzaki {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.storyblok.com">Storyblok, Gatsby.js &amp; TailwindCSS</a>
          </div>
          <Link to="/blog/cookie-policy">Cookie Policy</Link>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout